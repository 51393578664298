import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import RefreshIcon from '@mui/icons-material/Refresh';
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Alert, Backdrop, Box, Button, CircularProgress, Stack, Typography } from '@mui/material';
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import { DataGrid } from "@mui/x-data-grid";
import React, { useCallback, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import api from "../config/axiosConfigs";
import Dropzone from '../features/dropzone/Dropzone';
import './DocumentManagement.css';
import EditDocumentPopup from "../features/EditDocumentPopUp";
import UploadWithAttributes from "../features/attributeManagement/UploadWithAttributes";
import DeleteConfirmation from "../features/deleteConfirmation/DeleteConfirmation";
import ErrorBanner from '../features/errorBanner/errorBanner';
import { PdfViewer } from "../features/PdfViewer";

function DocumentManagement() {
  const maxFileSize = 19;
  const navigate = useNavigate();
  // Content of document list
  const [listContent, setListContent] = useState(null);

  // Upload handling
  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const [uploadMessage, setUploadMessage] = useState(null);
  const [uploadSuccessfull, setUploadSuccessfull] = useState(null);
  const [currentItemUpload, setCurrentItemUpload] = useState();

  // Upload handling - attributes
  const [authorName, setAuthorName] = useState();
  const [authorLastName, setAuthorLastName] = useState();
  const [fullTitle, setFullTitle] = useState();
  const [shortTitle, setShortTitle] = useState();
  const [creationDate, setCreationDate] = useState();

  // constants for handling the different document Actions
  const [showPDF, setShowPDF] = useState(); // VIEW: showPDF is set to the item_id of the currently shown item when one is selected, and set to false when none is shown
  const [urlPDF, setUrlPDF] = useState(); // VIEW: stores link for currently displayed PDF
  const [pdfViewerDisplay, setPdfViewerDisplay] = useState('none');
  const [currentItemDelete, setCurrentItemDelete] = useState(); // DELETE: currentItemDelete is set to the item_id and name of the item that is selected for deletion, or to false when none is selected
  const [editPopupOpen, setEditPopupOpen] = useState(false);  // EDIT
  const [editedDocument, setEditedDocument] = useState(null); // EDIT  
  const [error, setError] = useState(null);

  //Load the documents automatically when the page loads
  useEffect(() => {
    handleClickAPI()
  }, []);

  const handleClickEdit = (document) => {
    setEditedDocument(document);
    setEditPopupOpen(true);
  };
  
  useEffect(() => {
    // Call the API once when the component mounts
    handleClickAPI();
  }, []);

  // Handle click to update list of documents
  const handleClickAPI = (event) => {
    setIsUploadLoading(true)
    api.get("/listDocuments").then((response) => {
      console.log(response)
      console.log(response.data[0].documentTitle)
      setListContent(response.data)
      setIsUploadLoading(false)
      setUploadMessage(`Updated list.`)
      setUploadSuccessfull("success")
    }).catch((exeption) => {
      console.log(exeption)
      if(exeption.response.status===401){
        navigate('/login', {
          state:{
            error: "Session expired, please login"
          }
        });
      }else{
        setUploadMessage(`Internal Application Error. Please try again later.`)
        setUploadSuccessfull("error")
      }
      setIsUploadLoading(false)
    });
  };

  // Handle click of "Delete" button
  const handleClickDelete = (event, id, name) => {
    setCurrentItemDelete({
      id: id,
      name: name,
    })

  };

  // Handle click of confirmation in the "delete" confirmation window
  const handleClickConfirmDelete = (event) => {
    console.log("Confirming delete for document with ID " + currentItemDelete.id + " and name " + currentItemDelete.name)

    setCurrentItemDelete()
    setIsUploadLoading(true)

    api.delete(
      "/deleteItem/" + "id=" + currentItemDelete.id + "&name=" + currentItemDelete.name
      ).then((response) => {
      console.log("Delete call response:")
      console.log(response.data)
      setIsUploadLoading(false)
      setUploadMessage(`Successfully deleted file ${currentItemDelete.name}`)
      setUploadSuccessfull("success")
      handleClickAPI();
    }).catch((exeption) => {
      console.log(exeption)
      setUploadMessage(`Internal Application Error. Please try again later.`)
      setUploadSuccessfull("error")
      setIsUploadLoading(false)
    });
  };

  const handleClickCancelDelete = (e) => {
    setCurrentItemDelete()
  }

  // Creates a string of metadata attribute fields to attach to the upload REST Call
  const createMetadataPayload = () => {
    let metadataFields = ""

    if (authorName || authorLastName || fullTitle || shortTitle || creationDate) {
      metadataFields = "?"
    }

    if (authorName !== undefined) {
      if (metadataFields === "?") {
        metadataFields = metadataFields + "authorName=" + authorName
      } else {
        metadataFields = metadataFields + "&authorName=" + authorName
      }
    }

    if (authorLastName !== undefined) {
      if (metadataFields === "?") {
        metadataFields = metadataFields + "authorLastName=" + authorLastName
      } else {
        metadataFields = metadataFields + "&authorLastName=" + authorLastName
      }
    }

    if (fullTitle !== undefined) {
      if (metadataFields === "?") {
        metadataFields = metadataFields + "fullTitle=" + fullTitle
      } else {
        metadataFields = metadataFields + "&fullTitle=" + fullTitle
      }
    }

    if (shortTitle !== undefined) {
      if (metadataFields === "?") {
        metadataFields = metadataFields + "shortTitle=" + shortTitle
      } else {
        metadataFields = metadataFields + "&shortTitle=" + shortTitle
      }
    }

    if (creationDate !== undefined) {
      if (metadataFields === "?") {
        metadataFields = metadataFields + "creationDate=" + creationDate
      } else {
        metadataFields = metadataFields + "&creationDate=" + creationDate
      }
    }

    return metadataFields
  }

  // Handle click of confirmation in the "Upload" confirmation window
  const handleClickConfirmUpload = (event, file, formData) => {
    setCurrentItemUpload() // reset upload state - close pop-up

    console.log("Currently uploading: " + currentItemUpload.name)

    // Construct metadata payload
    let metadataFields = createMetadataPayload()
    console.log("Metadata Fields Payload: " + metadataFields)
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    // Clear state variables
    setAuthorName()
    setAuthorLastName()
    setFullTitle()
    setShortTitle()
    setCreationDate()

    setIsUploadLoading(true)
    console.log(formData)
    api.post("/upload" + metadataFields, formData, config).then((response) => {
      setIsUploadLoading(false)
      setUploadMessage(`Successfully uploaded file ${file.name}`)
      setUploadSuccessfull('success')
      handleClickAPI();
    }).catch((exeption) => {
      console.log(exeption)
      setIsUploadLoading(false)
      setUploadMessage("Error uploading file.")
      setUploadSuccessfull("error")
    });
  };

  const handleClickCancelUpload = (e) => {
    console.log("handleClickCancelUpload")
    setCurrentItemUpload()
  }

  const handleClickView = (e, item_id, item_name) => {
    console.log("handleClickView for " + item_id)

    // if the selected item is currently shown, hide it
    // if a new item is selected, switch to that one
    if (item_id === showPDF) {
      setShowPDF(false)
      return false
    }
    else {
      setShowPDF(item_id)
      api.post("/getPdfUrl?" + "id=" + item_id + "&name=" + item_name).then((response) => {
        let newList = listContent
        // Update table to store S3 URL in front-end so we don't need to call the API each time we want to show a PDF
        // TODO: actually use the table, only retrieve URL from back-end if it isn't stored in the listContent state yet
        for (const [key, value] of Object.entries(newList)) {
          if (value.id === item_id) {
            let newUrl = response.data
            newList[key].pdfUrl = newUrl
            
            console.log(newList[key])
            setUrlPDF(newUrl)
            // return newUrl
          }  
        } 
        console.log("Did not find ID in list.")
        // return false    
      }).catch((exeption) => {
        console.log(exeption)
        setUploadMessage(`Internal Application Error when attempting to view a document. Please try again later.`)
      });
    }
  }

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("name", file.name);
      if(file.size/(1024 * 1024) > maxFileSize){
        setError("File is too big, maximum allowed is 20MB");
      }
      else{
        setCurrentItemUpload({
          name: file.name,
          file: file,
          formData: formData,
        })
      }
    });
  }, []
  )

  // Definition of document management table structure
  const columns = [
    { 
      field: "id",
      renderHeader: () => (
        <strong>
          {'ID'}
        </strong>
      ),
      width: 70
    },
    {
      field: "documentTitle",
      renderHeader: () => (
        <strong>
          {'Document Name'}
        </strong>
      ),
      width: 200 },
    {
      field: "fullTitle",
      renderHeader: () => (
        <strong>
          {'Title'}
        </strong>
      ),
      type: "string",
      width: 200,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "authorFullName",
      renderHeader: () => (
        <strong>
          {'Author'}
        </strong>
      ),
      type: "string",
      minWidth: 150,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "citableDate",
      renderHeader: () => (
        <strong>
          {'Date (Citations)'}
        </strong>
      ),
      type: "string",
      minWidth: 150,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "tags",
      renderHeader: () => (
        <strong>
          {'Tags'}
        </strong>
      ),
      editable: false,
      minWidth: 200,
      renderCell: (params) => {
        const tagChips = params.value.map((tag, index) => (
          <Chip key={index} label={tag} style={{ marginRight: '5px' }} />
        ));
        return (
          <div>
            {tagChips}
          </div>
        );
      },
      headerAlign: "center"
    },
    {
      field: "timeOfUpload",
      renderHeader: () => (
        <strong>
          {"Time of Upload"}
        </strong>
      ),
      type: "string",
      minWidth: 150,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "actions",
      renderHeader: () => (
        <strong>
          {'Actions'}
        </strong>
      ),
      editable: true,
      width: 200,
      renderCell: (params) => {
        // const isRejected = params.value === "Rejected";
        // const value = params.value;
        const item_id = params.row.id
        const item_name = params.row.documentTitle
        return (
          <div>
            <IconButton size="100px" onClick={(e) => handleClickDelete(e, item_id, item_name)}>
              {" "}
              <DeleteIcon />{" "}
            </IconButton>
            <IconButton>
              {" "}
              <DownloadIcon />{" "}
            </IconButton>
            <IconButton sx = {{ color: ( showPDF === params.row.id ? 'primary.main' : 'grey' ) }} onClick={(e) => handleClickView(e, item_id, item_name)}>
              {" "}
              <VisibilityIcon />{" "}
            </IconButton>
            <IconButton>
              {" "}
              <EditIcon onClick={(e) => handleClickEdit(params.row)}/>{" "}
            </IconButton>
          </div>
        );
      },
      headerAlign: "center",
      align: "center"
    }
  ];

  return (
    <div style={{ width: "100%", marginTop: "10px"}}>

      {/* Backdrop when document upload process is ongoing */}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isUploadLoading}
      >
        <div direction="column">
          <CircularProgress color="inherit" />
          <Typography>Loading</Typography>
        </div>
      </Backdrop>

    	{/* Page Content */}
      
    <Stack direction="row" justifyContent="center" spacing={1} display="stretch" marginRight="10px" marginLeft="10px" marginBottom="10px" height="100%">
      <Box className="documentManagementTable" sx={{ overflow: 'auto', minWidth: "45vw", height: "fit-content" }}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          display="stretch"
          spacing={0}
          margin="20px"
        >
          
          <Dropzone onDrop={onDrop} accept={"image/*"} />

          {/* Delete Confirmation Dialogue */}
          {currentItemDelete && <DeleteConfirmation handleClickCancelDelete={handleClickCancelDelete} handleClickConfirmDelete={handleClickConfirmDelete} currentItemDelete={currentItemDelete} />}
          {uploadSuccessfull && <Alert severity={uploadSuccessfull} mt={2}>{uploadMessage}</Alert>}
          {/* Upload with Attributes Dialogue */}
          {currentItemUpload && <UploadWithAttributes handleClickCancelUpload={handleClickCancelUpload} handleClickConfirmUpload={handleClickConfirmUpload} currentItemUpload={currentItemUpload} attributeFields={{ authorName: authorName, setAuthorName: setAuthorName, authorLastName: authorLastName, setAuthorLastName: setAuthorLastName, fullTitle: fullTitle, setFullTitle: setFullTitle, shortTitle: shortTitle, setShortTitle: setShortTitle, creationDate: creationDate, setCreationDate: setCreationDate }} />}
          <Box component="span"
            sx={{
              // width: "650px", 
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
              display: 'block',
              paddingTop: '60px',
              p: 1,
              m: 1
            }}>
            <div style={{ height: 600, width: "100%" }}>
              <DataGrid
                rows={listContent ? (listContent) : ({})} // if no listContent was loaded yet: display "No rows"
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 10 }
                  }
                }}
                pageSizeOptions={[5, 10]}
                checkboxSelection
              />
            </div>
          </Box>
          <Button
            sx={{
              width: "300px",
              fontSize: "1.2rem",
              iconSizeMedium: { "& > *:first-child": { fontSize: 22 } }
            }}
            variant="outlined"
            startIcon={<RefreshIcon />}
            onClick={(e) => handleClickAPI(e)} >
            Update Table
          </Button>
        </Stack>
        {/* </div> */}
      </Box>
        {/* Right Side: PDF Viewer */}
        {showPDF && <Box sx={{ flex: 1, overflow: "auto", justifyContent: "center", minWidth: "35vw", marginRight: "10vh", marginTop:"85px !important"}} className="pdfViewer">
              <PdfViewer pdfUrl={urlPDF} selectedPage={1} setError={setError} setPdfViewerDisplay={setPdfViewerDisplay} setSelectedPdfUrl={setUrlPDF} />
        </Box>}

      {/* </Box> */}
      <div>
        <EditDocumentPopup
          open={editPopupOpen}
          onClose={() => setEditPopupOpen(false)}
          editedDocument={editedDocument}
          onSave={handleClickEdit} // Pass the updateTagsInTable function
          allTags={["KYC"]}
          errorSetter={setError}
        />
      </div>
      {error && <ErrorBanner message={error} errorSetter={setError} />}
    </Stack>
    </div>
    
  )
}

export default DocumentManagement;
