import React, { useState } from "react";
import {
    Typography,
    Paper,
    InputBase,
    IconButton,
    Divider,
    Popover,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
  } from "@mui/material";
import { config } from "./../../config/config";
import SendIcon from "@mui/icons-material/Send";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";

function QueryBar(props) {
    const llms = config.LLMS;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [llm, setLLM] = useState(0);
    const openPopOver = Boolean(anchorEl);
    const popoverId = openPopOver ? 'simple-popover' : undefined;

    const handleClickPopover = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    const handleQuery = (event, query) => {
        const queryText = query;
        props.setQuery("");
        props.handleQuery(event, queryText, llm);
    };

    const handleChange = (event) => {
        setLLM(event.target.value);
    };

    return (
        <Paper component="form" elevation={4} className="query-bar-container" sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: "100%", minHeight: 70, borderRadius: 12, padding: "20px" }}>
            {/* Query input */}
            <InputBase
                sx={{ ml: 1, flex: 1, padding: "20px" }}
                className="query-bar-input"
                multiline
                placeholder="Please enter search query"
                value={props.query}
                onChange={(e) => props.setQuery(e.target.value)}
                inputProps={{ "aria-label": "Query LLM" }}
            />

            {/* Search/Query Button */}
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search" onClick={(e) => handleQuery(e, props.query)} >
                <SendIcon color="primary" />
            </IconButton>

            {/* Divider*/}
            <Divider sx={{ height: 28, m: 0.5 }} className="query-bar-divider" orientation="vertical" />

            {/* Render selected LLM and Popover for selection */}
            {renderSelectedLLM()}
            {renderLLMSelectionPopover()}
        </Paper>
    );

    /* Render Selection of LLM component */
    function renderSelectedLLM() {
        return <IconButton sx={{ p: "10px" }} aria-label="menu" onClick={handleClickPopover}>
            <AutoAwesomeIcon color="primary" />
            <Typography><strong>{llms[llm].label}</strong></Typography>
        </IconButton>;
    }

    function renderLLMSelectionPopover() {
        return <Popover
            id={popoverId}
            open={openPopOver}
            anchorEl={anchorEl}
            onClose={handleClosePopover}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">LLM</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={llm}
                    onChange={handleChange}
                >
                    {llms !== undefined && llms.map((llm, index) => (
                        <MenuItem value={index}>{llm.label}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Popover>;
    }
}

export default QueryBar;

