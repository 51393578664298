import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { styled } from '@mui/material/styles';


const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
      color: '#A0AAB4',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#B2BAC2',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#E0E3E7',
      },
      '&:hover fieldset': {
        borderColor: '#B2BAC2',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#6F7E8C',
      },
    },
  });

function UploadWithAttributes( {handleClickCancelUpload, handleClickConfirmUpload, currentItemUpload, attributeFields} ) {
    return (
    <div>
        <div className="dialogue-backdrop"/>
            <Paper elevation={3} className="upload-with-attributes">
            <Box sx={{ m: 2 }}>
                <Typography gutterBottom variant="h5" padding="10px">
                <Divider>Document Upload</Divider>
                </Typography>
                <Typography color="text.secondary" variant="body1" component='div'>
                <Box component='span' fontWeight='fontWeightBold'>Currently uploading:</Box> {currentItemUpload.name}
                </Typography>
            </Box>
            <Divider variant="middle" />

            <Box 
            component="span"
            sx={{ 
              textAlign: "left", 
              alignItems: "left", 
              justifyContent:"left",
              display: 'block',
              p: 1,
              m: 1}}>
            <Stack 
              direction="column"
              justifyContent="left"
              alignItems="left"
              display="stretch"
              spacing={0}
            >
                <Typography color="text.secondary" variant="body1" width="100%" paddingBottom={1}>
                Author
                </Typography>
            <Stack 
              direction="row"
              justifyContent="left"
              alignItems="left"
              display="stretch"
              spacing={0}
            >
                <CssTextField 
                    label="First Name" 
                    id="author-first-name" 
                    onChange={(e) => attributeFields.setAuthorName(e.target.value)}
                    sx={{ m: 1 }}
                    />
                <CssTextField 
                    label="Last Name" 
                    id="author-last-name" 
                    onChange={(e) => attributeFields.setAuthorLastName(e.target.value)}
                    sx={{ m: 1 }}
                />
            </Stack>
            <Typography color="text.secondary" variant="body1" width="100%" paddingBottom={1} paddingTop={1}>
                Document Information
            </Typography>
            <Box>
                <Stack 
                direction="row"
                justifyContent="left"
                alignItems="left"
                display="stretch"
                spacing={0}
                >
                    <CssTextField 
                        label="Full Title" 
                        id="full-title" 
                        onChange={(e) => attributeFields.setFullTitle(e.target.value)}
                        sx={{ m: 1 }}
                    />
                </Stack>
                <Stack 
                direction="row"
                justifyContent="left"
                alignItems="left"
                display="stretch"
                spacing={0}
                >
                    <CssTextField 
                        label="Short Title" 
                        id="short-title" 
                        onChange={(e) => attributeFields.setShortTitle(e.target.value)}
                        sx={{ m: 1 }}
                    />
                </Stack>
                <Stack 
                direction="row"
                justifyContent="left"
                alignItems="left"
                display="stretch"
                spacing={0}
                >
                    <CssTextField 
                        label="Creation Date (YYYY-MM-DD)" 
                        id="creation-date" 
                        onChange={(e) => attributeFields.setCreationDate(e.target.value)}
                        sx={{ m: 1 }}
                    />
                </Stack>
            </Box>
            </Stack>
            </Box>
            
            <Box sx={{ mt: 3, ml: 1, mb: 1 }} paddingBottom="10px">
                <Button size="large" style={{ color: "grey" }} onClick={(e) => handleClickCancelUpload(e)}>Cancel</Button>
                <Button size="large" color="primary" onClick={(e) => handleClickConfirmUpload(e, currentItemUpload.file, currentItemUpload.formData)}>Upload Document</Button>
            </Box>
        </Paper>
    </div>
  );
}

export default UploadWithAttributes;