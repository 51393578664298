import MenuIcon from "@mui/icons-material/Menu";
import { IconButton, styled, ThemeProvider } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import * as React from "react";
import { Route, Routes } from "react-router-dom";
import './App.css';
import deloitte_logo from "./deloitte_logo.png";
import NavigationDrawer from './features/drawer/NavigationDrawer';
import { PdfViewer } from './features/PdfViewer';
import DocumentManagement from './pages/DocumentManagement';
import VectorSearchPage from "./pages/VectorSearchPage";
import EnhancedGoogleSearch from './pages/EnhancedGoogleSearch';
import SingleDocumentSearch from './pages/SingleDocumentSearch';
import customTheme from './theme/theme';
import Login from './pages/Login';
import Chatbot from './pages/Chatbot';
import FormFiller from "./pages/FormFiller";
import TokenValidationWrapper from "./auth/AuthWrapper";

function App() {
  const [loggedIn, setLoggedIn ] = React.useState(false);
  const CustomToolTip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      // color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 20,
    },
  }));

  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    //on reload, get variable loggedIn current session
    setLoggedIn(JSON.parse(window.localStorage.getItem('loggedIn')));
      }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <ThemeProvider theme={customTheme}>

      <div className="App">
        <div className="App-drawer" id="App-drawer">
          <NavigationDrawer open={open} handleDrawerClose={handleDrawerClose} />
        </div>
        <div className="App-main">
          {(open === false) && (<div className="App-drawer-button-closed" id="App-drawer-button">
            <CustomToolTip title="Open Sidebar" placement="right">
              <IconButton aria-label="Open Sidebar" color="primary" onClick={handleDrawerOpen} size="large">
                <MenuIcon></MenuIcon>
              </IconButton>
            </CustomToolTip>
          </div>)}
          <div className="Deloitte-Logo">
            <img src={deloitte_logo} alt="This is the deloitte logo" style={{ height: '100px' }} />
          </div>
          <div className="App-main-content">
            <Routes>
            <Route
                path="/"
                element={
                    <Login loginSetter={setLoggedIn} />
                }
              ></Route>
            <Route
                path="/login"
                element={<Login loginSetter={setLoggedIn}/>}
              ></Route>
              <Route
                path="/documentSearch"
                element={
                  <TokenValidationWrapper>
                    <SingleDocumentSearch />
                  </TokenValidationWrapper>
              }
              >
              </Route>
              <Route
                path="/chat"
                element={
                  <TokenValidationWrapper>
                <Chatbot />
                </TokenValidationWrapper>
                }
              ></Route>
              <Route
                path="/formFiller"
                element={
                  <TokenValidationWrapper>
                <FormFiller devmode="true" />
                </TokenValidationWrapper>
                }
              ></Route>
              <Route
                path="/internetSearch"
                element={
                  <TokenValidationWrapper>
                    <EnhancedGoogleSearch />
                  </TokenValidationWrapper>
              }
              ></Route>
              <Route
                path="/PDFViewer"
                element={
                  <TokenValidationWrapper>
                    <PdfViewer pdfUrl={"pdfUrl"} />
                  </TokenValidationWrapper>
              }
              ></Route>
              <Route
                path="/documentManagement"
                element={
                  <TokenValidationWrapper>
                    <DocumentManagement />
                  </TokenValidationWrapper>
              }
              ></Route>
              <Route
                path="/vectorSearch"
                element={
                  <TokenValidationWrapper>
                    <VectorSearchPage />
                  </TokenValidationWrapper>
              }
              ></Route>
            </Routes>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default App;
