import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, OutlinedInput, Select, Typography } from "@mui/material";

function SettingsDialog({ open, handleClose, language, handleChange, llms, llm, handleChangeLLM }) {
    // Dialog for accessing settings like microphone control, LLM name [implemented] and tags [TODO]
    return (
        <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
        <DialogTitle>Settings</DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
            {/* Not Used for AML yet */}
            {/* Select language for speech input */}
            <FormControl sx={{ m: 1, width: 400 }}>
              <InputLabel>Speech-To-Text</InputLabel>
              <Select
                native
                value={language}
                onChange={handleChange}
                input={<OutlinedInput label="Language" id="demo-dialog-native" />}
              >
                <option value={"en-GB"}>English</option>
                <option value={"de-DE"}>German</option>
              </Select>
            </FormControl>
            {/* Select LLM for query processing */}
            <FormControl sx={{ m: 1, width: 400 }}>
              <InputLabel id="demo-simple-select-label">LLM</InputLabel>
              <Select
                  native
                  value={llm}
                  onChange={handleChangeLLM}
                  input={<OutlinedInput label="LLM" id="demo-dialog-native" />}
              >
                  {llms !== undefined && llms.map((llm, index) => (
                      <option value={index}>{llm.label}</option>
                  ))}
              </Select>
          </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    )

}

export default SettingsDialog;