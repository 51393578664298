import React, { useState, useEffect } from "react";
import { Backdrop, Box, CircularProgress, Divider, FormControl, IconButton, InputBase, InputLabel, Link, MenuItem, Paper, Popover, Select, Stack, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import styled from "@emotion/styled";
import MultiselectDropdown from '../features/documentSearchDropDown/dropdown'
import ErrorBanner from '../features/errorBanner/errorBanner';
import {PdfViewer } from "../features/PdfViewer";
import '../features/PdfViewer.css';
import { useNavigate } from 'react-router-dom';
import api from "../config/axiosConfigs";

function SingleDocumentSearch() {

  const navigate = useNavigate();
  const [query, setQuery] = useState(null);
  const [selectedPdfUrl, setSelectedPdfUrl] = useState(null);
  const [pdfViewerDisplay, setPdfViewerDisplay] = useState('none');
  const [llmResultAnswer, setLlmResultAnswer] = useState(null);
  const [llmResultSources, setLlmResultSources] = useState(null);
  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [tags, setTags] = useState([]);
  const [error, setError] = useState(null);
  const [selectedPageSource, setSelectedPageSource] = useState(null);

  const Item = styled(Paper)(({}) => ({
    backgroundColor: "#fff",
    border: "0 solid #e5e7eb",
    padding: 10,
    textAlign: "center",
  }));

  const [llm, setLLM] = useState(0);
  const llms = [
    {
      label: "OpenAI (GPT-3.5-Turbo-16k)",
      name: "openai/gpt-3.5-turbo-16k"
    },
    {
      label: "OpenAI (GPT4)",
      name: "openai/gpt-4"
    },
    {
      label: "Nemo (GPT20B)",
      name: "nemo/gpt20b"
    },
    {
      label: "Nemo (GPT-43B-001)",
      name: "nemo/gpt-43b-001"
    },
    {
      label: "Nemo (GPT-43B-002)",
      name: "nemo/gpt-43b-002"
    },
  ]

  const handleClickPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };


  const handleClick = (event, text) => {
    setIsUploadLoading(true)
    setSelectedPdfUrl(null)
    const data = {
      "query": query,
      llm: llms[llm].name,
      "tags": tags
    }

    api.post("/search", {
      body: data,
      mode: 'cors'
    }
    ).then((response) => {
      setIsUploadLoading(false)
      if (response.data.answer === undefined || response.data.answer.length === 0) {
        setLlmResultAnswer("Sorry, but I couldn't generate a meaningful response for your query. Please try rephrasing your question or providing more context so I can better assist you.")
      } else {
        console.log()
        setLlmResultAnswer(response.data.answer)
      }

      if (response.data.sources !== undefined && response.data.sources.length > 0) {
        setLlmResultSources(response.data.sources)
      }
    }).catch((exeption) => {
      setIsUploadLoading(false)
      if(exeption.response?.status === 401){
        setError("Session expired");
        navigate('/login',  {
          state:{
            error: "Session expired, please login"
          }
        });
      }
      if (exeption.response?.status === 400) {
        setError("Query was empty!");
      }
      setError("Connection timed out")
    });
  };

  const handleChange = (event) => {
    setLLM(event.target.value);
  };

  const openPopOver = Boolean(anchorEl);
  const popoverId = openPopOver ? 'simple-popover' : undefined;

  const maxWidth = 1400;

  function get_pdf_url(document, page, start_line, end_line) {
    const data = {"document": document, "page": page, "start_line": start_line, "end_line": end_line}
    api.post("/getHighlightedDocument", {
      body: data,
      mode: 'cors'
    }).then((response) => {
      if (response.data.link === undefined) {
        setError("Failed to load highlighted PDF")
        setSelectedPdfUrl(null)
      } else {
        setSelectedPdfUrl(response.data.link);
        setSelectedPageSource(page);
      }
    }).catch((exeption) => {
      setError("Failed to load highlighted PDF")
      setSelectedPdfUrl(null)
    });
  }

  const renderTextWithLineBreaksAndSources = (textWithLinks, llmResultSources) => {
    const lines = textWithLinks.split('\n');
    return lines.map((line, index) => (
      <React.Fragment key={index}>
        {renderTextWithLinks(line, llmResultSources)}
        {index !== lines.length - 1 && <br />} {/* Add <br> except for the last line */}
      </React.Fragment>
    ));
  };

  function renderTextWithLinks(textWithLinks, llmResultSources) {
      const pattern = /(\[\d+\])/;
      const parts = textWithLinks.split(pattern).filter(Boolean);

      // Convert llmResultSources to a map for easier access
      const sourcesMap = new Map(llmResultSources.map(source => [source.id, source]));

      return parts.map(part => {
          const match = part.match(/\[(\d+)\]/);
                    if (match) {
              const sourceId = match[1];
                            const source = sourcesMap.get(sourceId);

              if (!source) {
                  // If no source is found for the given ID
                                    return "";
              } else {
                  const { document, page, start_line, end_line } = source;
                  return (
                      <Link
                          component="button"
                          variant="body2"
                          onClick={() => get_pdf_url(document, page, start_line, end_line)}
                          color="primary"
                      >
                          {part}
                      </Link>
                  );
              }
          } else {
              // Otherwise, return the plain text part.
              return part;
          }
      });
  }

  return (
    <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", marginTop: "100px" }}>
      {/* Loading Backdrop */}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isUploadLoading}
      >
        <div direction="column">
          <CircularProgress color="inherit" />
          <Typography>Loading</Typography>
        </div>
      </Backdrop>
        {/* Main Content */}
        <Box sx={{
          marginTop: "10px",
          display: "flex",
          width: "100%",
          overflow: "hidden",
          alignContent: "center",
          alignItems: "flexStart",
          justifyContent: "center",
          overflow: 'visible'
        }}>

          {/* Left Side Content */}
          <Stack direction="column" justifyContent="center" alignItems="center" spacing={2} sx={{ maxWidth: maxWidth, width: "100%", p: 1, m: 1}}>

            {/* Query Component */}
            <Paper
              component="form"
              elevation={4}
              sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', maxWidth: maxWidth, width: "100%", minHeight: 70, borderRadius: 12 }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                multiline
                placeholder="Please enter search query"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                inputProps={{ "aria-label": "search google maps" }}
              />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search" onClick={(e) => handleClick(e, query)} >
                <SearchIcon color="primary" />
              </IconButton>
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <IconButton sx={{ p: "10px" }} aria-label="menu" onClick={handleClickPopover}>
                <AutoAwesomeIcon color="primary" />
                <Typography><strong>{llms[llm].label}</strong></Typography>
              </IconButton>
              <Popover
                id={popoverId}
                open={openPopOver}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">LLM</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={llm}
                    onChange={handleChange}
                  >
                    {llms !== undefined && llms.map((llm, index) => (
                      <MenuItem value={index}>{llm.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Popover>
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <MultiselectDropdown tagsSetter={setTags} />
            </Paper>

            {/* Answer Component */}
            {llmResultSources && (<Stack direction="column" spacing={2} sx={{ maxWidth: maxWidth, width: "100%", padding: 2 }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignContent: "left",
                  bgcolor: 'background.paper',
                  borderRadius: 1,
                }}
              >
                <AutoAwesomeIcon size="large" color="primary" sx={{ paddingRight: 2 }}></AutoAwesomeIcon>
                <Typography><strong>Answer</strong></Typography>
              </Box>
              <Typography variant="body2" align="left">
                {llmResultAnswer && renderTextWithLineBreaksAndSources(llmResultAnswer, llmResultSources)}
                {/* {dummyAnswer.answer} */}
              </Typography>
            </Stack>)}

            {/* Sources Component */}
            {llmResultSources && (<Stack direction="column" spacing={2} sx={{ maxWidth: maxWidth, width: "100%", padding: 2 }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignContent: "left",
                  bgcolor: 'background.paper',
                  borderRadius: 1,
                }}
              >
                <AutoAwesomeIcon color="primary" sx={{ paddingRight: 2 }}></AutoAwesomeIcon>
                <Typography><strong>Sources</strong></Typography>
              </Box>
              <div style={{ width: "100%" }}>
                <Box sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(3, minmax(0,1fr))",
                  gap: "20px"
                }}>
                  {llmResultSources && llmResultSources.map((citation) => (
                    <Item elevation={4} sx={{ alignItems: "left" }}>
                      <Typography align="left"><strong>{citation.id}</strong> - {citation.document}</Typography>
                      <Typography align="left">{citation.info_text}</Typography>
                    </Item>
                  ))}
                </Box>
              </div>
            </Stack>)}
          </Stack>
          
        {/* Right Side Content */}
        {selectedPdfUrl && (
          <Box 
            sx={{ 
              p: 1, 
              m: 1,
              minWidth: "50%",
              overflow: "auto",
              display: `${pdfViewerDisplay}`              
              }}>
          <div className="pdfViewer" sx={{minHeight: "50%", overflow: "auto"}}>
            {selectedPdfUrl && <PdfViewer 
                                  pdfUrl={selectedPdfUrl}
                                  selectedPage={selectedPageSource} 
                                  setError={setError} 
                                  setPdfViewerDisplay={setPdfViewerDisplay} 
                                  setSelectedPdfUrl={setSelectedPdfUrl}
                                />}
          </div>
        </Box>)}
        </Box>
      <div>
        {error && <ErrorBanner message={error} errorSetter={setError} />}
      </div>
    </div>
  )

}

export default SingleDocumentSearch;