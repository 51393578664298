import React from "react";
import { useDropzone } from "react-dropzone";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import IconButton from '@mui/material/IconButton';
import { Typography } from "@mui/material";


function Dropzone({ onDrop, accept, open }) {
  const { getRootProps, getInputProps, isDragActive } =
    useDropzone({
      accept,
      onDrop,
    });

  // const files = acceptedFiles.map((file) => (
  //   <li key={file.path}>
  //     {file.path} - {file.size} bytes
  //   </li>
  // ));

  return (
    <div>
      {/* <Paper elevation={3}> */}
      <div {...getRootProps({ className: "dropzone" })}>
        <input className="input-zone" {...getInputProps()} />
        <div className="text-center">
          {isDragActive ? (
            <p className="dropzone-content">
              <Typography style={{ display: "flex", flexDirection: "row", alignItems: "center" }} variant="body1">Release to drop the files here</Typography>
            </p>
          ) : (
            <p className="dropzone-content">
              <Typography style={{ display: "flex", flexDirection: "row", alignItems: "center" }} variant="body1">Drag’n’drop some files here or click to select files</Typography>
            </p>
          )}
          <IconButton aria-label="delete" onClick={open} className="btn">
            <PictureAsPdfIcon color="primary" fontSize="large"/>
          </IconButton>
          {/* <button type="button" onClick={open} className="btn" icon>
            Click to select files
          </button> */}
        </div>
      </div>
      {/* </Paper> */}
      {/* <aside>
        <ul>{files}</ul>
      </aside> */}
    </div>
  );
}

export default Dropzone;