import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import TagsInput from './tags/TagsInput';
import axios from 'axios';
import { config } from "../config/config";
import Cookies from 'js-cookie';

const EditDocumentPopup = ({ open, onClose, editedDocument, errorSetter}) => {
  const url = config.API_URL;
  const [tags, setTags] = useState([]);

  //Initializes the Tags field when the pop up open
  useEffect(() => {
    if (editedDocument && editedDocument.tags){
      setTags(editedDocument.tags);
    }
  }, [editedDocument]);

  //Close the pop up and make the API call to store the updated info
  const handleSave = async () => {
    editedDocument.tags = tags;
    onClose();
    axios.post(url + "/updateDocument", {body: editedDocument},{
        headers: {
          Authorization: `Bearer ${Cookies.get('sessionToken')}`,
        },
        mode:'cors'
      }
    ).then(() => {}).catch((ex) => {
      errorSetter(ex.response.data.message);
    });
  };

  //sets the tags list
  const handleTagChange = (tags) => {
    setTags(tags);
    console.log(editedDocument.tags);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle>Edit Document Information</DialogTitle>
      <Typography color="text.secondary" variant="body1" align='left' marginLeft="25px" marginRight="25px">
                    {"Write a tag label and press enter to confirm, then press save."}
      </Typography>
      <DialogContent>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
          <TagsInput tags={tags} onTagsChange={handleTagChange} />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
 
export default EditDocumentPopup;
