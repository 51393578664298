import './tags.css'
function TagsInput({ tags, onTagsChange }) {
    function handleKeyDown(e) {
        if (e.key !== 'Enter') return;
        const value = e.target.value;
        if (!value.trim()) return;
        const newTags = [...tags, value];
        onTagsChange(newTags);  // Update tags in the parent component
        e.target.value = '';
    }

    function removeTag(index) {
        const newTags = tags.filter((el, i) => i !== index);
        onTagsChange(newTags);  // Update tags in the parent component
    }

    return (
        <div className="tags-input-container">
            {tags.map((tag, index) => (
                <div className="tag-item" key={index}>
                    <span className="text">{tag}</span>
                    <span className="close" onClick={() => removeTag(index)}>
                        &times;
                    </span>
                </div>
            ))}
            <input onKeyDown={handleKeyDown} type="text" className="tags-input" placeholder="Document Tags" />
        </div>
    );
}

export default TagsInput;
