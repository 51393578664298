const prod = {
     API_URL: process.env.API_ENDPOINT !== undefined ? process.env.API_ENDPOINT : "http://aml-api-development.d-roid.de/api",
     LLMS: [
        {
          label: "OpenAI (GPT-3.5-Turbo-16k)",
          name: "openai/gpt-3.5-turbo-16k"
        },
        {
          label: "OpenAI (GPT4)",
          name: "openai/gpt-4"
        },
        {
          label: "Nemo (GPT20B)",
          name: "nemo/gpt20b"
        },
        {
          label: "Nemo (GPT-43B-001)",
          name: "nemo/gpt-43b-001"
        },
        {
          label: "Nemo (GPT-43B-002)",
          name: "nemo/gpt-43b-002"
        },
      ] 
    };

const dev = {
    API_URL: "http://localhost:8080/api",
    LLMS: [
        {
          label: "OpenAI (GPT-3.5-Turbo-16k)",
          name: "openai/gpt-3.5-turbo-16k"
        },
        {
          label: "OpenAI (GPT4)",
          name: "openai/gpt-4"
        },
        {
          label: "Nemo (GPT20B)",
          name: "nemo/gpt20b"
        },
        {
          label: "Nemo (GPT-43B-001)",
          name: "nemo/gpt-43b-001"
        },
        {
          label: "Nemo (GPT-43B-002)",
          name: "nemo/gpt-43b-002"
        },
      ] 
};

export const config = process.env.NODE_ENV === "development" ? dev : prod;