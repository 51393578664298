import Mic from "@mui/icons-material/Mic";
import MicOff from "@mui/icons-material/MicOff";
import SendIcon from "@mui/icons-material/Send";
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { Divider, IconButton, InputBase, Paper } from "@mui/material";
import SettingsDialog from "../settings/SettingsDialog";
import SettingsIcon from '@mui/icons-material/Settings';
import { config } from "../../config/config";
import styled from "@emotion/styled";
import './EnhancedQueryBar.css'

const CustomToolTip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
        boxShadow: theme.shadows[1],
        fontSize: 20,
    },
}));

export function EnhancedQueryBar(
    {
        // Query 
        query,
        setQuery,
        handleQuery,

        // SpeechToText
        listening,
        handleMicOff,
        handleMicOn,
        transcript,
        resetTranscript,

        // SettingsDialog
        isSettingsDialogOpen,
        handleOpenSettings,
        handleCloseSettingsDialog,

        // Settings SpeechToText
        handleChangeLanguage,
        selectedLanguageSTT,

        // Settings Dialog
        llm,
        handleChangeLLM,

        // Reset ChatHistory
        handleResetHistory }) {
    const llms = config.LLMS
    return (
        <Paper
            // component="form"
            elevation={4}
            className="query-bar-container"
        >

            {/* Query input */}
            <InputBase
                className="query-bar-input"
                multiline
                placeholder="Please enter search query"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                inputProps={{ "aria-label": "Query LLM" }}
            />

            {/* Not Used for AML yet */}
            {/* Transcript input */}
            <InputBase
                sx={{ ml: 1, flex: 1, padding: "20px" }}
                className="transcript-bar-input"
                multiline
                disabled
                value={transcript}
                inputProps={{ "aria-label": "Transcript", style: { textAlign: "right" } }}
            />

            <CustomToolTip title={listening ? "Mute" : "Unmute"} placement="top">
                <IconButton sx={{ p: "10px" }} aria-label="menu" onClick={listening ? handleMicOff : handleMicOn}>
                    {listening ? <Mic color="primary" /> : <MicOff color="primary" />}
                </IconButton>
            </CustomToolTip>

            <CustomToolTip title="Reset Transcript" placement="top">
                <IconButton sx={{ p: "10px" }} aria-label="menu" onClick={resetTranscript}>
                    <DeleteOutlineIcon color="primary" />
                </IconButton>
            </CustomToolTip>

            {/* Divider*/}
            <Divider sx={{ height: 28, m: 0.5 }} className="query-bar-divider" orientation="vertical" />

            {/* Search/Query Button */}
            <CustomToolTip title="Query LLM" placement="top">
                <IconButton type="button" sx={{ p: "10px" }} aria-label="search" onClick={(e) => handleQuery(e, query, llm)} >
                    <SendIcon color="primary" />
                </IconButton>
            </CustomToolTip>

            <CustomToolTip title="Reset Chat History" placement="top">
                <IconButton size="100px" onClick={(e) => handleResetHistory(e)} color="primary">
                    <RefreshIcon color="primary" />
                </IconButton>
            </CustomToolTip>

            <CustomToolTip title="Settings" placement="top">
                <IconButton onClick={handleOpenSettings}>
                    <SettingsIcon color="primary" />
                </IconButton>
            </CustomToolTip>
            <SettingsDialog
                open={isSettingsDialogOpen}
                handleChange={handleChangeLanguage}
                language={selectedLanguageSTT}
                handleClose={handleCloseSettingsDialog}
                llms={llms}
                llm={llm}
                handleChangeLLM={handleChangeLLM}
            />
        </Paper>
    )
}