export const messageStyles = {
    loading: {
        color: 'grey',
        fontStyle: 'italic',
    },
    error: {
        color: 'red',
    },
};

export const customStyles = {
    control: (provided) => ({
        ...provided,
        backgroundColor: '#f2f2f2', // Background color
        border: '1px solid #ccc', // Border
        borderRadius: '25px', // Rounded corners
        minHeight: '40px', // Control height
        width: '200px',
        '&:hover': {
            border: '1px solid #aaa', // Hover effect
        },
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#43B02A' : 'white', // Selected and non-selected option background color
        color: state.isSelected ? 'white' : '#333', // Text color
        '&:hover': {
            backgroundColor: 'lightgrey', // Hover effect for non-selected option
            color: 'white', // Text color on hover
            borderRadius: '10px'
        },
    }),
    multiValue: (provided, state) => ({
        ...provided,
        backgroundColor: '#43B02A', // Multivalue background color
        borderRadius: '10px'
    }),
    multiValueLabel: (provided, state) => ({
        ...provided,
        color: 'white', // Multivalue label text color
        borderRadius: '10px'
    }),
    multiValueRemove: (provided, state) => ({
        ...provided,
        color: 'white', // Multivalue remove icon color
        ':hover': {
            backgroundColor: '#0056b3', // Hover effect for remove icon
            color: 'white', // Text color on hover
        },
    }),
    menu: (provided) => ({
        ...provided,
        backgroundColor: '#fff', // Background color of the dropdown container
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Box shadow
        border: '1px solid #ccc', // Border
        borderRadius: '5px', // Rounded corners
        marginTop: '4px', // Top margin
        width: '150px',
        marginLeft: '22px'
    }),
    menuList: (provided, state) => ({
        ...provided,
        padding: '8px', // Padding inside the dropdown container
        width: '150px'
    }),
};
